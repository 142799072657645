import Navbar from "./Components/Header"
import { Routes, Route, BrowserRouter} from "react-router-dom";
import PickShare from "./Pages/Portfolio/PickShare";
import BegottenDesigns from "./Pages/Portfolio/BegottenDesigns";
import OpticSoftware from "./Pages/Portfolio/OpticSoftware";
import Sanctify from "./Pages/Portfolio/Sanctify";
import AvantGroup from "./Pages/Portfolio/AvantGroup";
import Ekkino from "./Pages/Portfolio/Ekkino";
import Home from "./Pages/index"
import "./App.css"

function App() {
  return (
    <>
         <BrowserRouter>
         <Navbar/>
           <Routes>
           <Route path="/" element={<Home/>}></Route>
            <Route path="/PickShare" element={<PickShare/>}></Route>
            <Route path="/BegottenDesigns" element={<BegottenDesigns/>}></Route>
            <Route path="/OpticSoftware" element={<OpticSoftware/>}></Route>
            <Route path="/Sanctify" element={<Sanctify/>}></Route>
            <Route path="/AvantGroup" element={<AvantGroup/>}></Route>
            <Route path="/Ekkino" element={<Ekkino/>}></Route>
            {/*Add route here*/}
          </Routes>
          </BrowserRouter>
    </>
  );
}

export default App;
